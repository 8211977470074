import Vue from "vue";
import Router from "vue-router";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      component: () => import("./views/home"),
      meta: { authRequired: true },
    },
    {
      path: "/login",
      component: () => import("./views/login"),
      meta: { authRequired: false },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: { authRequired: false },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/register",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: { authRequired: false },
    },
    {
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: { authRequired: false },
    },
    {
      path: "/account",
      component: () => import("./views/account/account"),
      meta: { authRequired: true },
    },
    {
      path: "/account/indication",
      component: () => import("./views/account/indication"),
      meta: { authRequired: true },
    },
    {
      path: "/account/membership",
      component: () => import("./views/account/membership"),
      meta: { authRequired: true },
    },
    {
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: { authRequired: true },
    },
    {
      path: "/account/address",
      component: () => import("./views/account/address"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: { authRequired: true },
    },
    {
      path: "/contracts",
      component: () => import("./views/contracts/list"),
      meta: { authRequired: true },
    },
    {
      path: "/contracts/:id",
      component: () => import("./views/contracts/view"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: { authRequired: true },
    },
    {
      path: "/network/indicados",
      component: () => import("./views/network/indicados"),
      meta: { authRequired: true },
    },
    {
      path: "/network/equipe",
      component: () => import("./views/network/equipe"),
      meta: { authRequired: true },
    },
    {
      path: "/network/matriz",
      component: () => import("./views/network/matriz"),
      meta: { authRequired: true },
    },
    {
      path: "/crm/leads",
      component: () => import("./views/crm/leads"),
      meta: { authRequired: true },
    },
    {
      path: "/crm/clientes",
      component: () => import("./views/crm/clientes"),
      meta: { authRequired: true },
    },
    {
      path: "/apvs/rankings",
      component: () => import("./views/apvs/rankings"),
      meta: { authRequired: true },
    },
    {
      path: "/apvs/clientes",
      component: () => import("./views/apvs/clientes"),
      meta: { authRequired: true },
    },
    {
      path: "/apvs/recorrente",
      component: () => import("./views/apvs/recorrente"),
      meta: { authRequired: true },
    },
    {
      path: "/apvs/gerente",
      component: () => import("./views/apvs/gerente"),
      meta: { authRequired: true },
    },
    {
      path: "/apvs/diretor",
      component: () => import("./views/apvs/diretor"),
      meta: { authRequired: true },
    },
    {
      path: "/acessa/leads",
      component: () => import("./views/acessa/leads"),
      meta: { authRequired: true },
    },
    {
      path: "/acessa/clientes",
      component: () => import("./views/acessa/clientes"),
      meta: { authRequired: true },
    },
    {
      path: "/acessa/qrcodes",
      component: () => import("./views/acessa/qrcodes"),
      meta: { authRequired: true },
    },
    {
      path: "/acessa/adesao",
      component: () => import("./views/acessa/adesao"),
      meta: { authRequired: true },
    },
    {
      path: "/acessa/recorrente",
      component: () => import("./views/acessa/recorrente"),
      meta: { authRequired: true },
    },
    {
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: { authRequired: true },
    },
    {
      path: "/faq",
      component: () => import("./views/faq"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: { authRequired: true },
    },
    {
      path: "/courses",
      component: () => import("./views/courses/list"),
      meta: { authRequired: true },
    },
    {
      path: "/courses/:course",
      component: () => import("./views/courses/view"),
      meta: { authRequired: true },
    },
    {
      path: "/courses/:course/:module/:lesson",
      component: () => import("./views/courses/view"),
      meta: { authRequired: true },
    },
    {
      path: "/informatives",
      component: () => import("./views/informatives/index"),
      meta: { authRequired: true },
    },
    {
      path: "/informatives/:id",
      component: () => import("./views/informatives/view"),
      meta: { authRequired: true },
    },
    {
      path: "/events",
      component: () => import("./views/events/index"),
      meta: { authRequired: true },
    },
    {
      path: "/events/:id",
      component: () => import("./views/events/view"),
      meta: { authRequired: true },
    },
    {
      path: "/clients",
      component: () => import("./views/clients/index"),
      meta: { authRequired: true },
    },
    {
      path: "/clients/create",
      component: () => import("./views/clients/create"),
      meta: { authRequired: true },
    },
    {
      path: "/clients/import",
      component: () => import("./views/clients/import"),
      meta: { authRequired: true },
    },
    {
      path: "/clients/import/:id",
      component: () => import("./views/clients/import-file"),
      meta: { authRequired: true },
    },
    {
      path: "/clients/:id",
      component: () => import("./views/clients/edit"),
      meta: { authRequired: true },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: { authRequired: false },
    },
    {
      path: "/:sponsor",
      redirect: "/register/:sponsor",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = process.env.VUE_APP_TITLE;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == 'success') {
          router.app.$store.state.account.user = response.data.user;

          if (
            response.data.user.contracts &&
            response.data.user.contracts.length > 0 &&
            to.path != "/contracts/:id"
          ) {
            response.data.user.contracts.forEach(con => {
              return next("/contracts/" + con.id);
            })
          } else if (
            response.data.order &&
            response.data.order.id &&
            to.path != "/store/orders"
          ) {
            return next("/store/order/" + response.data.order.id);
          } else if (
            response.data.user.membership &&
            response.data.user.membership.status &&
            response.data.user.membership.status == 'pending' &&
            response.data.user.membership.order &&
            response.data.user.membership.order.id
          ) {
            return next("/store/order/" + response.data.user.membership.order.id);
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  }
});

export default router;
